import * as Sentry from '@sentry/remix';
import { replayIntegration } from '@sentry/react';
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import { startTransition, StrictMode, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';

Sentry.init({
  dsn: 'https://659614ff90b4b2992e1e488829e78160@o4507788420710400.ingest.us.sentry.io/4508201041920000',
  tracesSampleRate: 1,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    replayIntegration({
      maskAllText: false,
      blockAllMedia: true,
      maskAllInputs: true,
      networkDetailAllowUrls: [new RegExp('^' + window.location.origin)],
    }),
  ],

  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1,
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
